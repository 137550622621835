<template>
  <div class="" style="background: #fff">
    <Menu :list="menuList" />
    <EducacionFinancieraComponent />
    <Footer />
  </div>
</template>

<script >
import Menu from "../components/Menu.vue";
import EducacionFinancieraComponent from "@/components/EducacionFinanciera.vue";
import Footer from "../components/Footer3.vue";

import { ref, onBeforeMount } from 'vue';
import menuOptions  from "@/helpers/menu.js";

export default {
  name: "EducacionFinanciera",
  setup() {
    let menuList = ref([]);

    onBeforeMount(async () => {
      await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
        console.log("🚀 ~ file: EducacionFinanciera.vue:33 ~ awaitmenuOptions.getMenuOptions ~ resp", resp)
        menuList.value = resp.data.FONCABSA;
      }).catch(err => {
        console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList
    }
  },
  components: {
    Menu,
    EducacionFinancieraComponent,
    Footer,
  },
};
</script>
