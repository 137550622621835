<template>
       <div class="my-10 md:my-20 mx-5 md:mx-52">
            <!-- Ahorro -->
            <div style="font-size:20px">
                <div>
                    <h2 class="font-extrabold color-purple text-3xl RobotoMedium">Educación Financiera</h2>
                    <div class="liner-yellow"></div>
                </div>
                
                    <div class="mt-20">
                        <h5 class="font-semibold color-purple text-2xl RobotoMedium">Ahorro</h5>
                        <h4 class="font-semibold color-grey mt-10 RobotoMedium"> ¿Qué es el ahorro?</h4>
                        <p class="color-grey text-info">Hábito de guardar una parte de nuestro ingreso para conseguir una meta, cumplir un sueño o crear un fondo que nos permita enfrentar alguna emergencia.</p>

                        <h4 class="font-semibold color-grey mt-10 RobotoMedium"> ¿Por qué es bueno ahorrar?</h4>
                        <p class="color-grey text-info"> El ahorro fortalece el bienestar de las familias, incrementa la estabilidad económica personal, familiar y patrimonial.</p>

                        <h4 class="font-semibold color-grey mt-10 
                        ">TIP</h4>
                        <p class="color-grey text-info">Considerar el ahorro como un rubro más de nuestro presupuesto familiar nos ayuda a guardar nuestro dinero con disciplina y constancia.</p>
                    </div>

                    <div class="mt-10"   style="height: 2px; width: 100%;background:#72665973"></div>

                    <div class="mt-20">
                        <h3 class="font-semibold color-purple RobotoMedium text-2xl">Presupuesto familiar</h3>
                        <h4 class="font-semibold color-grey mt-10 RobotoMedium ">¿Qué es el presupuesto familiar?</h4>
                        <p class="color-grey text-info">Es una herramienta utilizada semanal, quincenal o mensualmente que permite conocer cuánto dinero se necesita para cubrir las necesidades de la familia.</p>

                        <h4 class="font-semibold color-grey mt-10 RobotoMedium">¿Para qué sirve?</h4>
                        <p class="color-grey text-info">Para definir prioridades familiares e individuales, organizar el dinero y hacerlo rendir más, mejorar los hábitos de consumo y gastar con responsabilidad.</p>

                        <h4 class="font-semibold color-grey mt-10 RobotoMedium">TIP</h4>
                        <p class="color-grey text-info">Distribuye de forma racional el ingreso. Separa del ingreso una cantidad fija para ahorrarla, se recomienda generalmente el 10%.</p>
                    </div>

                    <div class="mt-10"   style="height: 2px; width: 100%;background:#72665973"></div>


                  <div class="mt-20">
                        <h3 class="font-semibold color-purple RobotoMedium text-2xl">Consumo responsable</h3>
                        <h4 class="font-semibold color-grey mt-10 RobotoMedium">¿Qué implica el consumo responsable?</h4>
                        <p class="color-grey text-info">Implica consumir menos, consumir solo lo necesario estando atentos a no tener necesidades superfluas.</p>

                        <h4 class="font-semibold color-grey mt-10 RobotoMedium">¿Qué es el consumo responsable?</h4>
                        <p class="color-grey text-info">Es racionalizar, aspirar a productos que realmente sean necesarios y no adquirir por consumir.</p>

                        <h4 class="font-semibold color-grey mt-10 RobotoMedium">TIP</h4>
                        <p class="color-grey text-info">Debemos de satisfacer necesidades no únicamente deseos.</p>
                    </div>

 
                    
            </div>
            <!-- Fin Ahorro -->
    </div>
</template>

<script>

export default {
    name: 'EducacionFinancieraComponent'
}

</script>

<style scoped>
 
.liner-yellow{
   width: 100px;
    background: #f8c603;
    height: 5px; 
}

.color-purple{
    color: #810042!important;
}
.color-grey{
    color:#726659;
}

.text-info{
    font-family: "RobotoRegular";
}
</style>